export const blogData = [
    {
        id: 1,
        title: "NO LONGER AVAILABLE",
        description: "No Longer Available",
        date: "01/01/2000",
        image: "",
        url: "",
    },
];
