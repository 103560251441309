import daveCalleja from "../assets/jpg/testimonials/DaveCalleja.jpeg";
import michalTrunek from "../assets/jpg/testimonials/MichalTrunek.jpeg";
import josefCohen from "../assets/jpg/testimonials/JosefCohen.jpeg";
import paulZammit from "../assets/jpg/testimonials/PaulZammit.jpeg";
import stefanScerri from "../assets/jpg/testimonials/StefanScerri.jpeg";
import ryanCalleja from "../assets/jpg/testimonials/RyanCalleja.jpeg";
import omarZammit from "../assets/jpg/testimonials/OmarZammit.jpeg";
import christopherMicallef from "../assets/jpg/testimonials/ChristopherMicallef.jpeg";
import remyAzzopardi from "../assets/jpg/testimonials/RemyAzzopardi.jpeg";
import johanFarrugia from "../assets/jpg/testimonials/JohanFarrugia.jpeg";
import mireiaOlive from "../assets/jpg/testimonials/MireiaOlive.jpeg";

export const testimonialsData = [
    {
        id: 11,
        name: "Johan Farrugia",
        title: "Full-stack Developer at Tipico Co. Ltd",
        text: [
            "I've known Philip for nearly 6 years now, and it's been a pleasure to work with him in the same department. Philip is a diligent, self-driven individual who strives to achieve his goals. ",
            "He's also a very friendly and approachable person, always offering his assistance whenever it's needed.",
            " I also consider Philip to be a proactive person, readily proposing new ideas and expressing his point of view in an honest and constructive manner, which makes him an ideal team-mate to work with.",
            "Philip is not only extremely proficient in frontend development, where he's considered as a point-of-reference on this matter in our department, but also very technically sound when it comes to the rest of the tech stack that the company uses.",
            " In addition, he also continuously invests time in acquiring new skills whilst also applying them in practice, and for these reasons he is undoubtedly a great asset to have within one's team/department.",
            " I therefore strongly recommend Philip for any position related to software development.",
        ],
        image: johanFarrugia,
    },
    {
        id: 10,
        name: "Remy Azzopardi",
        title: "Software Developer at Tipico",
        text: [
            "Had the pleasure to work alongside Philip for the past four years at Tipico. Philip is driven, motivated, passionate, highly technical, and always there to lend a hand.",
            " Be it a technical issue or simply bouncing off of ideas you can always count on him.",
            " His skills are essential in reaching team goals and project delivery. He is careful with meeting deadlines and strives to deliver quality work.",
            " He is enthusiastic about learning new skills and is always on the lookout for improving tasks/processes and automating when possible.",
            "I personally enjoyed working with Philip, he is easy to be around, comitted, and gets the job done.",
        ],
        image: remyAzzopardi,
    },
    {
        id: 9,
        name: "Christopher Micallef",
        title: "Software Architect at Tipico",
        text: [
            "Philip is one of a kind developer. Very rarely, in my 20+ year long career, have I come across someone who combines outstanding technical capabilities with unparalleled artistic flair like Philip does. ",
            "You could tell that Philip has been part of any piece of work by just glancing at the end product. Philip is a passionate person who takes pride in producing high quality software. Philip is also a great person and team player. ",
            "He is understandable, sensible and easy to get along with. These qualities make him a perfect team player. Another characteristic which I admire in Philip is his honesty and straightforwardness. ",
            "I believe this attribute is the key to good, long lasting work relationships. ",
            "Philip would be a great asset to any organisation. Whilst wholeheartedly recommending Philip, I do hope that our paths will cross again someday.",
        ],
        image: christopherMicallef,
    },
    {
        id: 8,
        name: "Josef Cohen",
        title: "Frontend Developer at Tipico Co Ltd",
        text: [
            "I have worked with Philip on various frontend projects at Tipico.  Philip is particularly very meticulous. For example, he would typically deliver tasks with high quality code, testing and documentation. ",
            "He also likes to help others to deliver such standards by being actively suggestive and supportive. From my experience, I can confidently say that Philip is a generally a great guy to work with.",
            " Not only because he is technically strong, but also because he is super nice and helpful, and a real team player.",
            " I would highly recommend Philip.",
        ],
        image: josefCohen,
    },
    {
        id: 7,
        name: "Michal Trúnek",
        title: "Senior QA Automation Engineer at Lokalise",
        text: [
            "I had a pleasure to meet Philip at Tipico. Throughout the years on many different projects, Philip showed his great skills in many areas. I could talk about the programming or solving skills, but for me he will always be a great person.",
            " No matter what, Phil always takes the time to support anyone is his network. He can sense when someone is down and is always trying to cheer him/her up.",
            " I wish, I could work with him one day again and certainly I can give my full recommendation.",
        ],
        image: michalTrunek,
    },
    {
        id: 1,
        name: "Dave Calleja",
        title: "Head of Web Development at Markor TechnologyHead of Web Development at Markor Technology",
        text: [
            "Throughout my career I have known bad developers, good developers and great developers who are team players. Philip was one of the latter. ",
            'Not only does he know his craft well, but facilitates the team even if it means stepping outside of the typical "developer" role. ',
            "These are the types of people one would want to work with. People who do what it takes to make sure the whole team progresses.",
        ],
        image: daveCalleja,
    },
    {
        id: 2,
        name: "Paul Zammit",
        title: "Product Manager, Product Owner, Product Specialist",
        text: [
            "Philip is one of those developers that every team or company needs. He always comes in with a positive attitude, and not only he does his job and he does it well, Philip makes sure others are getting the help they need, and then fills the gap for anything else that is left to be done to make sure everyone around him are performing at their absolute best.",
            "Not only is Philip a great developer and an incredibly fast learner, Philip brings in the bigger picture mentality, and takes into consideration with his and every thought and action that it's not just about the technical side of things, but that Philip is within a whole ecosystem of tech, users, product, marketing, and everything else that makes the business tick.",
            "If I had to build an all stars dream team, Philip will be one of the first I would call up to join.",
        ],
        image: paulZammit,
    },
    {
        id: 3,
        name: "Stefan Scerri",
        title: "Product Specialist | Experience in Building High Scale Digital Experiences & Teams",
        text: [
            "I worked with Philip during my time at Tipico as a Product manager. ",
            "He was part of the software team and one of the key Frontend developers. From the very first day he joined our team, I had no doubt that his input and expertise will be instrumental into improving the tech stack and helping the business grow.",
            "Philip is a fast thinker who can quickly visualise a solution once a problem is thrown at him. ",
            "It is for this reason that we always picked him to work on high stake projects like the one of building a new mobile product which contributed to large amounts of revenue. Philip's development speed and power had a big positive impact on the timing and delivery of this project. ",
            "Philip possesses an entrepreneurs mindset as his self-starter attitude put him at the forefront of improving processes and embracing change.",
            " His attitude is a big plus to the team as through his example and approach he effectively motivates others to take on new challenges. ",
            "Overall, I believe Philip is a natural leader who has a very bright future ahead. Should the opportunity arise, he is a key person I would instantly pick to start a new initiative with. ",
            "I highly recommend Philip as he is a game changer and great asset to any team. ",
        ],
        image: stefanScerri,
    },
    {
        id: 4,
        name: "Ryan Calleja",
        title: "Software Engineer Frontend",
        text: [
            "I have worked with Philip for a year during my time at Tipico. Philip is a highly determined individual who enjoys a good challenge and always manages to succeed in whatever he sets his mind into. As a developer he always showed interest in any corner of the system and never failed to learn where he was not so knowledgable. ",
            "Since his character flourishes in being organised, he always took it in his hands to take control of some of the daily routines required by his team. ",
            "Philip is a professional in frontend development with great knowledge in Css, Javascript, AngularJs, ReactJS, Webpack and Node. Although employed as a frontend developer, he also managed to grasp areas in the backend code which was written in Java. ",
            "This shows that he can potentially be a superb all rounder within a team. ",
            "Having his communication skills is a must have within a team and his sense of humour makes it fun to be within his team. ",
            "I look forward to cross paths with Philip again to work along side of him and would totally recommend him to anyone looking for a developer to get things done without compromising deadlines.",
        ],
        image: ryanCalleja,
    },
    {
        id: 5,
        name: "Omar Zammit",
        title: "Developer, Lecturer and PhD student",
        text: [
            "Few words that can describe Philip are, good eye for detail, perfectionist and keen of trying new technologies and problem solving techniques. He is always there to help out and share his experience and knowledge.",
            " Philip is also a very sociable person and I highly recommend as a team member.",
        ],
        image: omarZammit,
    },
    {
        id: 6,
        name: "Mireia Olive",
        title: "Country Manager at Financer.com",
        text: [
            "It is rare to meet someone like Philippe, he always shows passion and dedication towards work. I worked with Philip at B3W Group where he came up with the idea and developed the amazing tool (http://yachting-casino.dev.b3w/urls.html); a dashboard with all the company websites (over 40).",
            "This tool really helped to all employees, making our daily much easier; saving time and costs to the company. Philippe is a really standout talent, it has been a pleasure to work with him. Any employee would be lucky to have Philip on his team!",
        ],
        image: mireiaOlive,
    },
];
