import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

import { ThemeContext } from "../../contexts/ThemeContext";

import expImgWhite from "../../assets/svg/experience/expImgWhite.svg";
import expImgBlack from "../../assets/svg/experience/expImgBlack.svg";

import "./Experience.css";

function ExperienceCard({ id, company, jobTitle, startYear, endYear, industry, image, teams = [] }) {
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        experienceCard: {
            backgroundColor: theme.primary30,
            "&:hover": {
                backgroundColor: theme.primary50,
            },
        },
    }));
    const classes = useStyles();

    return (
        <Fade bottom>
            <div key={id} className={`experience-card ${classes.experienceCard}`}>
                <div className="expcard-img" style={{ backgroundColor: theme.primary }}>
                    {image ? (
                        <img src={image} alt={company} />
                    ) : (
                        <img src={theme.type === "light" ? expImgBlack : expImgWhite} alt="" />
                    )}
                </div>
                <div className="experience-details">
                    <h6 style={{ color: theme.tertiary }}>
                        <span>{startYear}</span> - <span>{endYear}</span>
                    </h6>
                    <h4 style={{ color: theme.tertiary }}>{jobTitle}</h4>
                    <h5 style={{ color: theme.tertiary80 }}>{company}</h5>
                    <span style={{ color: theme.tertiary50 }}>{industry}</span>
                    {teams.length > 0 && (
                        <span className="teams" style={{ color: theme.tertiary50 }}>
                            <h5>TEAMS</h5>
                            {teams.map((team) => (
                                <span
                                    key={`${id}-${team}`}
                                    className={"team"}
                                    style={{ backgroundColor: theme.primary50 }}
                                >
                                    {team}
                                </span>
                            ))}
                        </span>
                    )}
                </div>
            </div>
        </Fade>
    );
}

export default ExperienceCard;
