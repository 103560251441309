import { commonData } from "./commonData";

export const headerData = {
    name: "Philip Vella",
    title: "Principal Frontend Engineer",
    description: [
        "A results-driven Principal Frontend Engineer with over a decade of experience in designing, developing, and optimising modern web applications. " +
            "Collaboration is at the core of my approach, working closely with cross-functional teams and Product Management to deliver innovative and high-quality solutions to customers. " +
            "I am passionate about staying ahead in the ever-evolving tech world, using the latest technologies to drive product performance, scalability, and business-value.",
    ],
    resumePdf: commonData.resumePdf,
};
